import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';

import Seo from "../components/seo";
import Layout from "../components/layout";

import "../styles/global/base.scss";
import "../styles/news-detail.scss";

const NewsPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const title = frontmatter.title;
  const ogpImage = (!frontmatter.ogpImage) ? null : frontmatter.ogpImage.publicURL;
  const html = data.markdownRemark.html;

  return (
    <React.Fragment>
      <Seo title={title} pageClass="newsDetailPage" ogpImage={ogpImage} />
      <Layout>
        <Container fluid className="newsContainer">
          <Row>
            <Col md={{ span: 10, offset: 1 }} className="newsHeader">
              <h4>News</h4>
              <h1>{title}</h1>
              <p className="date">{frontmatter.date}</p>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="newsBody">
              <div className="inner" dangerouslySetInnerHTML={{__html: html}} />
            </Col>
            <Col md={{ span: 8, offset: 2 }} className="newsFooter">
              <Link to="/news/">News Index →</Link>
            </Col>
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default NewsPage;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        ogpImage {
          publicURL
        }
      }
    }
  }
`;
